import React, { Component } from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from './author-images.module.css'
//import { responsiveTitle3 } from './typography.module.css'

function AuthorImages(props) {
  return (
    <div className={styles.authors}>
        <div className={styles.avatars}>
        {props.authors.map(entity => (
            <>
                {entity.main_image &&
                    <span key={entity._id} className={styles.avatar}>
                        <GatsbyImage className={styles.avatarImg} image={getImage(entity?.main_image?.imageFile)} alt={entity.main_image.alternativeText ? entity.main_image.alternativeText : entity.name } />
                    </span>
                }
            </>
        ))}
        </div>
    </div>
  )
}

export default AuthorImages
