import React from 'react'

import { Link } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import { format } from 'date-fns'

import { getPageUrl } from '../lib/helpers'

import AuthorImages from '../components/author-images'

import * as styles from './blog-post-preview.module.css'
//import { responsiveTitle3 } from './typography.module.css'

function BlogPostPreview(props) {

  var page_has_parent = props.page_has_parent || false
  if( props?.page_has_parent && props?.parent ){
    if(props.parent.slug != page_has_parent.slug){
      // not the same parent so treat as if it doesn't have one.
      page_has_parent = false
    }
  }
  return (
    <Link
      className={props.isInList ? styles.inList : styles.inGrid}
      to={getPageUrl(props)}
    >
      <div className={styles.leadMediaThumb}>
        {
          props.main_image ? 
          <GatsbyImage image={getImage(props.main_image?.imageFile)} alt={props.main_image.alternativeText ? props.main_image.alternativeText : props.title} />
          : <StaticImage src="../images/placeholder.png" alt=""/>
        }
      </div>
      <div className={styles.text}>

        { page_has_parent &&
         // if this page has a parent the use the linking page's sub info 
          <span className={ styles.category }>{props.title_short? props.title_short: props.primary_topic}</span>
        }
        { !page_has_parent && !props.parent && ( props.primary_topic || props.title_short ) &&
          // if this page doesn't have parent, and the linking page doesn't have parent then use the linking page's primary topic
          <span className={ styles.category }>{props.primary_topic? props.primary_topic : props.title_short}</span>
        }
        { !page_has_parent && props.parent && (props.parent.title_short || props.parent.primary_topic) &&
         // if this page doesn't have a parent, then use the linking page's parent
          <span className={ styles.category }>{props.parent.title_short? props.parent.title_short : props.parent.primary_topic}</span>
        }
        <span id="title" className={ styles.title}>{props.title}</span>
        {props.excerpt && (
          <div className={styles.excerpt}>
            {props.excerpt}
          </div>
        )}
        { false && props.authors && props.authors.length>0 &&
            <AuthorImages authors={props.authors}/>
        }
        { props.created_at && 
            <>Published {format( Date.parse(props.created_at), "MMMM do, yyyy")}</>
        }
        { props.updated_at && props.created_at && (Date.parse(props.updated_at)-60*60*24 > Date.parse(props.created_at)) && 
            <> Updated {format( Date.parse(props.updated_at), "MMMM do, yyyy")}</>
        }
      </div>
    </Link>
  )
}

export default BlogPostPreview
